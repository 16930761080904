import React, { useContext,  useEffect, useState, useRef } from "react"
import { LanguageContext } from "../../context"
import Banner from "../banner"
import Button from "../button-visit-us"
import Button2 from "../button-generic"
import ButtonModal from "../buttons/button-cta-top-content"
import { navigate, Link } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import Icon from "../icon"
import Utils from "../../utils"

const ConnectPage = (props) => {

  const {
    post,
    bannerHeight="408px",
    ministries,
    promoted,
    buttonBanner,
    toknowus,
    buttonBannerFormId,
    modalTitle,
    modalDescription,
    modalFooterText,
    backgroundImage
  } = props
  const { t } = useContext(LanguageContext)
  const breakpoints = useBreakpoint()




  return(
    <div className="page-connect">
    <Banner
      post={post}
      height={bannerHeight}
      buttonBanner={buttonBanner}
      hasButton={true}
      buttonBannerFormId={buttonBannerFormId}
      modalTitle={modalTitle}
      modalDescription={modalDescription}
    />
    <section className="section-to-know-us p-t-80 p-b-80 medium-p-b-54 small-p-t-24 small-p-l-r-16 small-p-b-64">
        <div className="container p-0 toknowus aaa">
        <h2 className="H2-Medium-C---brown p-b-88 medium-m-b-74 small-m-b-16 medium-p-b-0 small-p-b-0">
          {t("Coneix-nos")}
        </h2>
        <SectionToKnowUs
          post={toknowus}
          title={t("Coneix-nos")}
          imagePos="left"
          color="bordeaux"
          modalTitle={modalTitle}
          modalDescription={modalDescription}
          modalFooterText={modalFooterText}
        />
        <SectionToKnowUs
          post={toknowus}
          title={t("Coneix-nos")}
          imagePos="right"
          color="bordeaux"
        />
      </div>
    </section>

    <section className="section-to-visit-us p-t-80 p-b-88 dark-green-section medium-p-t-0 small-p-t-0 small-p-l-r-16 small-p-b-40">

        <div className={`${breakpoints.md ? 'container-unida' : 'container p-0 '} tovisitus`}>
        <h2 className="H2-Medium-C---green-dark p-b-34 medium-m-b-40 small-m-b-16 medium-p-b-0 small-p-b-0 text-center">
          {t("Visita’ns")}
        </h2>
        {
          breakpoints.md
          ?
          <>
          <SectionToVisitUsMobile
            post={toknowus}
            imagePos="left"
            pos={1}
          />
          <SectionToVisitUsMobile
            post={toknowus}
            imagePos="right"
            pos={2}
            t={t}
          />
          </>
          :
          <>
            <SectionToVisitUs
              post={toknowus}
              imagePos="left"
              pos={1}
            />
            <SectionToVisitUs
              post={toknowus}
              imagePos="right"
              pos={2}
              t={t}
            />
          </>
        }

      </div>

    </section>


    <section className="section-connect p-t-80 p-b-88 background-beig medium-p-t-56 medium-p-b-20 small-p-t-24 small-p-l-r-16 small-p-b-40">
        <div className="container p-0 connect-with-us">
        <h2 className="H2-Medium-C---brown p-b-32 medium-m-b-16 small-m-b-16">
          {t("Connecta’t")}
        </h2>
        <div className="col-12">
          <div className="H5-Medium-C---black- connect-body-intro text-center m-b-80 medium-p-0 medium-m-b-48 medium-p-l-r-24 small-p-l-r-0 small-m-b-32">
            <div dangerouslySetInnerHTML={{__html: toknowus?.field_connect_body?.value}} />

            <div className="row">
               <div className="container">
                  <div className="col-12 button-cta">
                        <ButtonModal
                           className="btn btn-default btn-outline-visit-us brown"
                           title={toknowus?.field_connect_cta?.title}
                           backgroundimage={toknowus?.relationships?.field_meet_image_1?.image_style_uri?._653_432}
                           formId='visit_us_tramuntana'
                           modalTitle={modalTitle}
                           modalDescription={modalDescription}
                           modalFooterText={modalFooterText}
                         />
                  </div>
               </div>
            </div>

          </div>
        </div>
        <SectionConnectUs
          t={t}
          pos={1}
          key={1}
          imageFirst={toknowus?.relationships?.field_connect_related_image_1?.image_style_uri?._541x359}
          titleFirst={toknowus?.field_connect_related_title_1}
          descFirst={toknowus?.field_connect_related_body_1?.value}
          urlFirst={Utils.removeCaUrl(toknowus?.field_connect_related_cta_1?.url)}
          urlFirstTitle={toknowus?.field_connect_related_cta_1?.title}
          imageSecond={toknowus?.relationships?.field_connect_related_image_2?.image_style_uri?._541x359}
          titleSecond={toknowus?.field_connect_related_title_2}
          descSecond={toknowus?.field_connect_related_body_2?.value}
          urlSecond={Utils.removeCaUrl(toknowus?.field_connect_related_cta_2?.url)}
          urlSecondTitle={toknowus?.field_connect_related_cta_2?.title}
        />
        <SectionConnectUs
          t={t}
          pos={2}
          key={2}
          imageFirst={toknowus?.relationships?.field_connect_related_image_3?.image_style_uri?._541x359}
          titleFirst={toknowus?.field_connect_related_title_3}
          descFirst={toknowus?.field_connect_related_body_3?.value}
          urlFirst={Utils.removeCaUrl(toknowus?.field_connect_related_cta_3?.url)}
          urlFirstTitle={toknowus?.field_connect_related_cta_3?.title}
          imageSecond={toknowus?.relationships?.field_connect_related_image_4?.image_style_uri?._541x359}
          titleSecond={toknowus?.field_connect_related_title_4}
          descSecond={toknowus?.field_connect_related_body_4?.value}
          urlSecond={Utils.removeCaUrl(toknowus?.field_connect_related_cta_4?.url)}
          urlSecondTitle={toknowus?.field_connect_related_cta_4?.title}
        />
      </div>
    </section>



    <section className="section-make-difference p-t-80 p-b-88 dark-green-section medium-p-t-40 medium-p-b-0 small-p-t-24 small-p-l-16 small-p-b-64">
        <div className="container p-0 make-difference">
        <h2 className="H2-Medium-C---brown m-b-32 medium-m-b-16 small-m-b-16">
          {t("Fes la diferència")}
        </h2>
        <div className="col-12">
          <div className="H5-Medium-C---black- connect-body-intro text-center m-b-80 medium-m-b-48 medium-p-l-r-24 small-p-0 small-m-b-32">
            <div dangerouslySetInnerHTML={{__html: toknowus?.field_mark_difference_body?.value}} />
          </div>
        </div>
        <SectionMakeDifference
          t={t}
          pos={1}
          key={1}
          imageFirst={toknowus?.relationships?.field_markdif_related_image_1?.image_style_uri?._541x359}
          titleFirst={toknowus?.field_markdif_related_title_1}
          descFirst={toknowus?.field_markdif_related_body_1?.value}
          urlFirst={Utils.removeCaUrl(toknowus?.field_markdif_related_cta_1?.url)}
          imageSecond={toknowus?.relationships?.field_markdif_related_image_2?.image_style_uri?._541x359}
          titleSecond={toknowus?.field_markdif_related_title_2}
          descSecond={toknowus?.field_markdif_related_body_2?.value}
          urlSecond={Utils.removeCaUrl(toknowus?.field_markdif_related_cta_2?.url)}
          ctaFirstValue={toknowus?.field_markdif_related_cta_1?.title}
          ctaSecondValue={toknowus?.field_markdif_related_cta_2?.title}
        />
      </div>
    </section>

    </div>
  )
}

const SectionToKnowUs = (props) => {
  const {
      title,
      post,
      imagePos,
      modalTitle,
      modalDescription,
      modalFooterText
   } = props
  const breakpoints = useBreakpoint()

  const firstItemRef = useRef(null);
  const imgRef = useRef(null);
  const [imgHeight, setImgHeight] = useState(0);

  const handleImageLoad = () => {
    if (imgRef.current) {
      setImgHeight(imgRef.current.clientHeight);
    }
  };

  useEffect(() => {
    if (firstItemRef.current) {
      firstItemRef.current.style.height = `${imgHeight}px`;
    }
  }, [imgHeight]);

if(imagePos === "left"){
  return(
    <div className="row img-left toknowus-item m-b-120 medium-m-b-114">
       <div className="col-12 col-sm-6 background-bordeux first-item max-width-683 image-wrapper" ref={firstItemRef}>
          <img
            src={post?.relationships?.field_meet_image_1?.image_style_uri?._653_432}
            alt={post?.field_meet_cta_1?.title}
            className="m-r--24"
            onLoad={handleImageLoad}
            ref={imgRef}
          />
       </div>
       <div className="col-12 col-sm-6 p-l-80 p-r-169 medium-p-48-24 small-p-0">
          <div className="m-b-32" dangerouslySetInnerHTML={{__html: post?.field_field_meet_body_1?.value}} />
          <div className="row">
             <div className="container">
                <div className="col-12 button-cta align-left">
                   <ButtonModal
                      className="btn btn-default btn-outline-visit-us brown"
                      title={post?.field_meet_cta_1?.title}
                      backgroundimage={post?.relationships?.field_meet_image_1?.image_style_uri?._653_432}
                      formId='visit_us_tramuntana'
                      modalTitle={modalTitle}
                      modalDescription={modalDescription}
                      modalFooterText={modalFooterText}
                    />
                </div>
             </div>
          </div>
       </div>
    </div>
  )
  }

  if(imagePos === "right"){
    if(breakpoints.xs){
      return(
        <div className="row img-left toknowus-item m-b-120 medium-m-b-114">
           <div className="col-12 col-sm-6 background-bordeux first-item max-width-683 image-wrapper">
              <img
                src={post?.relationships?.field_meet_image_2?.image_style_uri?._653_432}
                alt={post?.field_meet_cta_2?.title}
                className="m-r--24"
              />
           </div>
           <div className="col-12 col-sm-6 p-l-80 p-r-169 medium-p-48-24 small-p-0">
              <div className="m-b-32" dangerouslySetInnerHTML={{__html: post?.field_meet_body_2?.value}} />
              {/*
              <div className="row">
                 <div className="container">
                    <div className="col-12 button-cta align-left">
                       <Button
                          className="btn btn-default btn-outline-visit-us brown"
                          title={post?.field_meet_cta_2?.title}
                          />
                    </div>
                 </div>
              </div>
              */}
           </div>
        </div>
      )
    }else{
      return(
        <div className="row img-right toknowus-item pastoral-team">
           <div className="col-12 col-sm-6 p-l-169 p-r-82 m-r-3p medium-p-24-48 small-p-0">
              <div className="m-b-32" dangerouslySetInnerHTML={{__html: post?.field_meet_body_2?.value}} />
              {/*
              <div className="row hide">
                 <div className="container">
                    <div className="col-12 button-cta align-left">
                       <Button2
                          className="btn btn-default btn-outline-visit-us brown"
                          title={post?.field_meet_cta_2?.title}
                          onClick={() => navigate("/equip-pastoral")}
                          />
                    </div>
                 </div>
              </div>
              */}
           </div>
           <div className="col-12 col-sm-6 background-bordeux second-item max-width-683 small-p-0">
              <img
                src={post?.relationships?.field_meet_image_2?.image_style_uri?._653_432}
                alt={post?.field_meet_cta_2?.title}
                className="m-r--24"
              />
           </div>
        </div>
      )
    }
  }
}



const SectionToVisitUs = (props) => {
  const {title, post, imagePos, pos, t} = props
  const breakpoints = useBreakpoint()

if(imagePos === "left" && pos === 1){
  return(
    <div className="row img-left visit-us-item m-b-120 medium-m-b-83">
       <div className="col-6 first-item max-width-683">
          <img
            src={post?.relationships?.field_visit_image_1?.image_style_uri?._653_432}
            alt={post?.field_visit_cta_1?.title}
            className="m-r--24"
          />
       </div>
       <div className="col-6 p-l-56 p-r-169 medium-p-l-r-25">
          <div className="m-b-32 m-t-88 medium-m-t-0 small-m-t-0-" dangerouslySetInnerHTML={{__html: post?.field_visit_body_1?.value}} />
       </div>
    </div>
  )
  }


    return(
      <div className="row  second m-b-66- medium-m-r-2 p-t-208 medium-p-t-0">
        <div className="col-md-6 second-first m-w-625 p-l-r-0- m-r-75- p-l-10 m-r-61 ">
          <div className="ministry-image background-beig read-more m-b-42">
            <img
              src={post?.relationships?.field_visit_image_3?.image_style_uri?._653_432}
              alt={post?.field_visit_cta_3?.title}
              className="m-t--24 m-l-112 m-w-540"
            />
             <Link
              to={Utils.removeCaUrl(post?.field_visit_cta_3?.url)}
               className="r-more grey-dark"
             >
              {post?.field_visit_cta_3?.title}
              <Icon icon="icons-32-x-32-generic-plus" size={16} className="icon" />
             </Link>
          </div>
          <div className="ministry-title-desc">
            <div dangerouslySetInnerHTML={{__html: post?.field_visit_body_3?.value}} />
          </div>
        </div>
        <div className="col-md-6 second-second m-t--304 m-w-623 p-l-r-0 ">
          <div className="ministry-image background-beig read-more m-b-42">
            <img
              src={post?.relationships?.field_visit_image_2?.image_style_uri?._653_432}
              alt={post?.field_visit_cta_2?.title}
              className="m-t--24 m-l-112 m-w-540"
            />
             <Link
              to={Utils.removeCaUrl(post?.field_visit_cta_2?.url)}
               className="r-more grey-dark"
             >
              {post?.field_visit_cta_2?.title}
              <Icon icon="icons-32-x-32-generic-plus" size={16} className="icon" />
             </Link>
          </div>
          <div className="ministry-title-desc">
            <div dangerouslySetInnerHTML={{__html: post?.field_visit_body_2?.value}} />
          </div>
          <div className="third p-t-80">
          <div className="ministry-image background-beig read-more m-b-42">
            <img
              src={post?.relationships?.field_visit_image_4?.image_style_uri?._653_432}
              alt={post?.field_visit_cta_4?.title}
              className="m-t--24 m-l-112 m-w-540"
            />
             <Link
              to={Utils.removeCaUrl(post?.field_visit_cta_4?.url)}
               className="r-more grey-dark"
             >
              {post?.field_visit_cta_4?.title}
              <Icon icon="icons-32-x-32-generic-plus" size={16} className="icon" />
             </Link>
          </div>
          <div className="ministry-title-desc">
            <div className="m-b-8" dangerouslySetInnerHTML={{__html: post?.field_visit_body_4?.value}} />
            <div className="unida-social-media">
              <div dangerouslySetInnerHTML={{__html: post?.field_visit_social_media_body?.value}} />
            </div>
          </div>
          </div>
        </div>
      </div>
    )

}

const SectionToVisitUsMobile = (props) => {
  const {title, post, imagePos, pos, t} = props

if(imagePos === "left" && pos === 1){
  return(
    <div className="row img-left visit-us-item m-b-120 medium-m-b-83 first medium-p-l-r-24 small-m-b-20">
       <div className="col-12 col-sm-6 first-item max-width-683 small-m-b-8">
          <img
            src={post?.relationships?.field_visit_image_1?.image_style_uri?._653_432}
            alt={post?.field_visit_cta_1?.title}
            className="m-r--24"
          />
       </div>
       <div className="col-12 col-sm-6">
          <div className="m-b-32 m-t-88 medium-m-t-0 small-m-t-0 small-m-b-0" dangerouslySetInnerHTML={{__html: post?.field_visit_body_1?.value}} />
       </div>
    </div>
  )
  }


    return(
      <>
      <div className="row second small-m-b-20"> {/* first */}
        <div className="col-12 col-sm-12 ministry-image background-beig read-more m-b-42 medium-m-w-348 medium-m-b-82">
          <img
            src={post?.relationships?.field_visit_image_3?.image_style_uri?._653_432}
            alt={post?.field_visit_cta_3?.title}
            className="m-t--24 m-l-112 m-w-540 medium-m-l-24 medium-m-w-348"
          />
          <Button2
           title={t("Veure més")}
           icon="icons-32-x-32-generic-plus"
           iconSize={16}
           iconColor="#ff0"
           iconClassName="icon"
           className="r-more grey-dark"
           onClick={() => navigate(post?.items[0]?.node?.path?.alias)}
           />
        </div>
        <div className="col-12 col-sm-12 ministry-title-desc medium-p-l-64 medium-p-r-24 small-p-l-r-0">
          <div dangerouslySetInnerHTML={{__html: post?.field_visit_body_3?.value}} />
        </div>
      </div>

      <div className="row third"> {/* second */}
        <div className="col-12 col-sm-6 ministry-image background-beig read-more m-b-42 medium-m-w-348 medium-m-b-82">
          <img
            src={post?.relationships?.field_visit_image_2?.image_style_uri?._653_432}
            alt={post?.field_visit_cta_2?.title}
            className="m-t--24 m-l-112 m-w-540 medium-m-l-24 medium-m-w-348"
          />
          <Button2
           title={t("Veure més")}
           icon="icons-32-x-32-generic-plus"
           iconSize={16}
           iconColor="#ff0"
           iconClassName="icon"
           className="r-more grey-dark"
           onClick={() => navigate(post?.items[0]?.node?.path?.alias)}
           />
        </div>
        <div className="col-12 col-sm-6 ministry-title-desc medium-p-l-64 medium-p-r-24 small-p-l-r-0">
          <div dangerouslySetInnerHTML={{__html: post?.field_visit_body_2?.value}} />
        </div>
      </div>

      <div className="row forth"> {/* third */}

        <div className="col-12 col-sm-6 ministry-image background-beig read-more m-b-42 medium-m-w-348 medium-m-b-82">
          <img
            src={post?.relationships?.field_visit_image_4?.image_style_uri?._653_432}
            alt={post?.field_visit_cta_3?.title}
            className="m-t--24 m-l-112 m-w-540 medium-m-l-24 medium-m-w-348"
          />
          <Button2
           title={t("Veure més")}
           icon="icons-32-x-32-generic-plus"
           iconSize={16}
           iconColor="#ff0"
           iconClassName="icon"
           className="r-more grey-dark"
           onClick={() => navigate(post?.items[0]?.node?.path?.alias)}
           />
        </div>
        <div className="col-12 col-sm-6 ministry-title-desc medium-p-l-64 medium-p-r-24 small-p-l-r-0">
          <div className="m-b-8" dangerouslySetInnerHTML={{__html: post?.field_visit_body_4?.value}} />
          <div className="unida-social-media">
            <div dangerouslySetInnerHTML={{__html: post?.field_visit_social_media_body?.value}} />
          </div>
        </div>

      </div>

      {/*
      <div className="second m-b-66 medium-m-r-2 p-t-208 medium-p-t-0">
        <div className="second-first row">
          <div className="col-6 ministry-image background-beig read-more m-b-42 medium-m-w-348">
            <img
              src={post?.relationships?.field_visit_image_3?.image_style_uri?._653_432}
              alt={post?.field_visit_cta_3?.title}
              className="m-t--24 m-l-112 m-w-540 medium-m-l-24 medium-m-w-348"
            />
            <Button2
             title={t("Veure més")}
             icon="icons-32-x-32-generic-plus"
             iconSize={16}
             iconColor="#ff0"
             iconClassName="icon"
             className="r-more grey-dark"
             onClick={() => navigate(post?.items[0]?.node?.path?.alias)}
             />
          </div>
          <div className="col-6 ministry-title-desc">
            <div dangerouslySetInnerHTML={{__html: post?.field_visit_body_3?.value}} />
          </div>
        </div>
      </div>
      */}
      {/*
        <div className="row-">
        <div className="row second-second m-t--304 m-w-625 p-l-r-0 ">
          <div className="col-6 ministry-image background-beig read-more m-b-42 medium-m-w-348">
            <img
              src={post?.relationships?.field_visit_image_2?.image_style_uri?._653_432}
              alt={post?.field_visit_cta_2?.title}
              className="m-t--24 m-l-112 m-w-540 medium-m-l-24 medium-m-w-348"
            />
            <Button2
             title={t("Veure més")}
             icon="icons-32-x-32-generic-plus"
             iconSize={16}
             iconColor="#ff0"
             iconClassName="icon"
             className="r-more grey-dark"
             onClick={() => navigate(post?.items[0]?.node?.path?.alias)}
             />
          </div>
          <div className="col-6 ministry-title-desc">
            <div dangerouslySetInnerHTML={{__html: post?.field_visit_body_2?.value}} />
          </div>
        </div>
        </div>

        */}


      </>
    )

}

const SectionConnectUs = (props) => {
  const {
    t,
    pos,
    imageFirst,
    titleFirst,
    descFirst,
    urlFirst,
    imageSecond,
    titleSecond,
    descSecond,
    urlSecond,
    urlFirstTitle,
    urlSecondTitle
  } = props

  const breakpoints = useBreakpoint()

  return(
    <div className={`row small-p-l-r-0 ${ pos === 1 ? 'm-b-80' : ''} medium-m-b-40 small-m-0 small-m-b-32`}>
      <div className={`${breakpoints.md ? 'col-sm-2' : 'col-sm-3 m-r-11'}`}></div>
      <div className={`${breakpoints.md ? 'col-sm-4' : 'col-sm-3'} col-6 ministry-item`}>
        <div>
          <div className="ministry-image background-white small-m-b-4">
            <img
              src={imageFirst}
              alt={titleFirst}
              className="m-r--24"
            />
          </div>
        </div>
        <div className="p-r-60 small-p-0">
          <h2 className="title ministry-title m-b-8"> {titleFirst} </h2>
          <div className="ministry-desc">
            <div className="m-b-16" dangerouslySetInnerHTML={{__html: descFirst }} />
          </div>
          <Link
            to={urlFirst}
            className="r-more brown right"
          >
            {urlFirstTitle}
            <Icon icon="icons-32-x-32-generic-plus" size={16} className="icon" />
          </Link>
        </div>
      </div>

      <div className={`${breakpoints.md ? 'col-sm-4' : 'col-sm-3'} col-6 ministry-item`}>
        <div>
          <div className="ministry-image background-white small-m-b-4">
            <img
              src={imageSecond}
              alt={titleSecond}
              className="m-r--24"
            />
          </div>
        </div>
        <div className="p-r-60 small-p-0">
          <h2 className="title ministry-title m-b-8"> {titleSecond} </h2>
          <div className="ministry-desc">
          <div className="m-b-16" dangerouslySetInnerHTML={{__html: descSecond }} />
          </div>
          <Link
            to={urlSecond}
            className="r-more brown right"
          >
            {urlSecondTitle}
            <Icon icon="icons-32-x-32-generic-plus" size={16} className="icon" />
          </Link>
        </div>
      </div>
      <div className={`${breakpoints.md ? 'col-sm-2' : 'col-sm-3'}`}></div>

    </div>
  )
}

const SectionMakeDifference = (props) => {
  const {
    t,
    pos,
    imageFirst,
    titleFirst,
    descFirst,
    urlFirst,
    imageSecond,
    titleSecond,
    descSecond,
    urlSecond,
    ctaFirstValue,
    ctaSecondValue
  } = props

  const breakpoints = useBreakpoint()

  return(
    <div className={`row small-p-l-r-0 ${ pos === 1 ? 'm-b-80-' : ''} medium-m-b-40 small-m-0`}>
      {!breakpoints.md &&
        <div className="col-sm-2 m-r-11"></div>
      }

      <div className={`${breakpoints.md ? 'col-sm-6' : 'col-sm-4 col-6'} ministry-item small-m-b-24`}>
        <div>
          <div className="ministry-image background-green m-b-16 small-m-b-10">
            <img
              src={imageFirst}
              alt={titleFirst}
              className="m-r--24"
            />
          </div>
        </div>
        <div className="p-r-60 small-p-0">
          <h2 className="title ministry-title m-b-8"> {titleFirst} </h2>
          <div className="ministry-desc m-b-31">
            <div dangerouslySetInnerHTML={{__html: descFirst }} />
          </div>
            <Link
             title={ctaFirstValue}
             className="r-more grey-dark right"
             to={urlFirst}
             >
             {ctaFirstValue}
             </Link>
        </div>
      </div>

      <div className={`${breakpoints.md ? 'col-sm-6' : 'col-sm-4 col-6'} ministry-item`}>
        <div>
          <div className="ministry-image background-green m-b-16 small-m-b-10">
            <img
              src={imageSecond}
              alt={titleSecond}
              className="m-r--24"
            />
          </div>
        </div>
        <div>
          <h2 className="title ministry-title m-b-8"> {titleSecond} </h2>
          <div className="ministry-desc m-b-31 small-m-b-24">
          <div dangerouslySetInnerHTML={{__html: descSecond }} />
          </div>
          <Link
           title={ctaSecondValue}
           className="r-more grey-dark right"
           to={urlSecond}
           >
           {ctaSecondValue}
           </Link>
        </div>
      </div>
      {!breakpoints.md &&
        <div className="col-sm-2"></div>
      }

    </div>
  )
}


 export default ConnectPage
